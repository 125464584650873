export enum Permission {
  EDIT_ADMIN = "EDIT_ADMIN",
  ADD_ADMIN = "ADD_ADMIN",
  LIST_ADMIN = "LIST_ADMIN",
  EDIT_WORKER = "EDIT_WORKER",
  ADD_WORKER = "ADD_WORKER",
  LIST_WORKER = "LIST_WORKER",
  LIST_CLIENT = "LIST_CLIENT",
  ADD_ORDER = "ADD_ORDER",
  UPDATE_ORDER = "UPDATE_ORDER",
  LIST_MEMBERSHIPS = "LIST_MEMBERSHIPS",
  LIST_ORDERS = "LIST_ORDERS",
  CONTACT_USER = "CONTACT_USER",
  ADD_CHECKER = "ADD_CHECKER",
  LIST_CHECKER = "LIST_CHECKER",
  EDIT_CHECKER = "EDIT_CHECKER",
  LIST_TICKET = "LIST_TICKET",
  EDIT_TICKET = "EDIT_TICKET",
  ADD_TICKET = "ADD_TICKET",
  LIST_EVENTS = "LIST_EVENTS",
  ADD_EVENT = "ADD_EVENT",
  EDIT_EVENT = "EDIT_EVENT",
  LIST_SUPER_ADMIN = "LIST_SUPER_ADMIN",
  LIST_PRODUCT = "LIST_PRODUCT",
  ADD_PRODUCT = "ADD_PRODUCT",
  EDIT_PRODUCT = "EDIT_PRODUCT",
  LIST_INVENTORY = "LIST_INVENTORY",
  ADD_INVENTORY = "ADD_INVENTORY",
  LIST_SALES = "LIST_SALES"
}
