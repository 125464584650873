
/**
 * The main toolbar
 */
import Vue from "vue";
// import Search from "../Search.vue";
import {
  BtnUser,
  // BtnUpgrade,
  // BtnInvite,
  // BtnTranslate,
} from "@/components/buttons";
import BtnCallInprogress from "@/views/CallCenter/buttons/BtnCallInprogress.vue";
import { mapMutations, mapState } from "vuex";
import BtnNotifications from "../buttons/BtnNotifications.vue";
import { apiBaseUrl } from "@/enviorment";
import axios from "axios";
//import BtnChangeStatus from "@/components/buttons/BtnChangeStatus.vue";

export default Vue.extend({
  name: "main-toolbar",
  data() {
    return {
      isAdmin: false,
      loadingImage: false,
      noimage: false,
      logo: "",
    };
  },
  components: {
    // BtnChangeStatus,
    // Search,
    BtnUser,
    BtnCallInprogress,
    BtnNotifications,

    // BtnUpgrade,
    // BtnInvite,
    // BtnTranslate,
  },
  props: {
    appBarProps: Object,
  },
  computed: {
    ...mapState(["displaylogo"]),
  },
  methods: {
    ...mapMutations(["mutShowDrawer"]),

    getLogo() {
      this.loadingImage = true;
      this.noimage = false;

      const url = `${apiBaseUrl}/minio/getLogo`;
      axios({
        method: "get",
        url: url,
        responseType: "blob",
      })
        .then((res) => {
          const buffer = new Blob([res.data]);
          const file_path = URL.createObjectURL(buffer);
          this.logo = file_path;
          this.noimage = false;
          this.loadingImage = false;
        })
        .catch((error) => {
          this.noimage = true;
          this.loadingImage = false;
        });
    },
  },
  mounted() {
    if (this.$store.state.profile.rol == "ADMIN") {
      this.isAdmin = true;
    }

    this.getLogo();
  },
});
