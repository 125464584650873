
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { GET_STATUS_COLOR } from "@/store/getters/types";
import { ACT_CHANGE_STATUS } from "@/store/actions/types";

export default Vue.extend({
  name: "btn-change-status",
  props: {
    status: String,
    admin: Boolean,
  },
  data() {
    return {
      statuses: [
        {
          value: "Idle",
          icon: "mdi-human-greeting-proximity",
          color: "success",
        },
        /* {
          value: "Busy",
          icon: "mdi-account-clock",
          color: "error",
        },
        {
          value: "Reserved",
          icon: "mdi-account-outline",
          color: "warning",
        }, */
        {
          value: "Offline",
          icon: "mdi-account-off",
          color: "secondary",
        },
      ],
      stateModel: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters([GET_STATUS_COLOR]),

    getIcon(): string {
      return this.statuses.filter((stat) => stat.value == this.stateModel)[0] !=
        undefined
        ? this.statuses.filter((stat) => stat.value == this.stateModel)[0].icon
        : "";
    },
    getColor(): string {
      return this.statuses.filter((stat) => stat.value == this.stateModel)[0] !=
        undefined
        ? this.statuses.filter((stat) => stat.value == this.stateModel)[0].color
        : "";
    },
  },
  mounted() {
    this.stateModel = this.$store.state.profile.agentStatus;
  },
  methods: {
    ...mapActions({ setStatus: ACT_CHANGE_STATUS }),
    ...mapMutations(["updateStatus"]),
    changeStatus(status: string | undefined): void {
      this.loading = true;
      this.$store.state.profile.agentStatus = status;
      this.updateStatus(status);
      this.setStatus(status).then(() => {
        this.loading = false;
      });
    },
  },
});
