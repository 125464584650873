<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-snackbar top timeout="-1" rounded="pill" outlined v-model="callinprogress">
    <div class="d-flex">
      {{ "Call in progress: " }} {{ callhours }}:{{ callminutes }}:{{
        callseconds
      }}
      <v-spacer></v-spacer>
      <template v-if="initRecord">
        <div class="red--text flashed">
          {{ initRecord ? "Recording..." : "" }}
        </div>
      </template>
    </div>

    <template>
      <v-progress-linear :value="callVal" :buffer-value="totalTime">
        <div class="timeline">
          <div
            v-for="(mark, i) in marks"
            :key="i"
            :style="{ left: mark.percentage + '%' }"
            class="marker"
          ></div>
        </div>
      </v-progress-linear>
    </template>

    <template v-slot:action="{ attrs }">
      <template v-if="initRecord">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              color="error"
              v-on="on"
              text
              v-bind="attrs"
              @click="stopMark()"
              icon
            >
              <v-icon>mdi-stop</v-icon>
            </v-btn>
          </template>
          <span>Stop Recording</span>
        </v-tooltip>
      </template>
      <template v-else>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="error"
              text
              v-bind="attrs"
              @click="markCall()"
              icon
            >
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </template>
          <span>Start Recording</span>
        </v-tooltip>
      </template>
    </template>
  </v-snackbar>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { mapMutations, mapState } from "vuex";
import { notifyError } from "../Notification";
import moment from "moment";
export default {
  name: "call-progress-component",
  props: {
    initCall: Object,
    recharge: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      text: "",
      initRecord: false,
      counterRecord: 0,
      callElapsed: 0,
      intervalCall: null,
      marks: [],
    };
  },
  computed: {
    ...mapState("crmCallCenterModule", ["callinprogress"]),
    callhours() {
      return String(Math.floor(this.callElapsed / 3600)).padStart(2, "0");
    },
    callminutes() {
      return String(Math.floor((this.callElapsed % 3600) / 60)).padStart(
        2,
        "0"
      );
    },
    callseconds() {
      return String(this.callElapsed % 60).padStart(2, "0");
    },
    totalTime() {
      return 3600;
      if (this.callElapsed < 30) {
        return 60;
      }
      if (this.callElapsed < 60) {
        return 120;
      }
      if (this.callElapsed < 120) {
        return 360;
      }
      if (this.callElapsed < 360) {
        return 600;
      }
      if (this.callElapsed < 600) {
        return 1200;
      }
      if (this.callElapsed < 1200) {
        return 3600;
      }
    },

    callVal() {
      const percentage = (100 * this.callElapsed) / this.totalTime;

      return percentage;
    },
  },
  watch: {
    callinprogress(val) {
      if (val) {
        this.startCall();
      } else {
        this.stopCall();
      }
    },
  },
  methods: {
    ...mapMutations("crmCallCenterModule", ["mutCallProgress"]),

    closecall() {
      this.mutCallProgress(false);
    },
    markCall() {
      let body = Object.assign({}, this.initCall);
      body = { ...body, initial: true };

      delete body.timeMarcInit;

      delete body.timeMarcEnd;

      getAPI
        .put("/zoom/mark-call-time", body)
        .then((res) => {
          this.initRecord = true;
          this.markTime();
        })
        .catch((err) => {
          let mess = err.response.data.message.toString();

          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },
    stopMark() {
      let body = Object.assign({}, this.initCall);
      body = { ...body, initial: false };

      delete body.timeMarcInit;

      delete body.timeMarcEnd;

      getAPI
        .put("/zoom/mark-call-time", body)
        .then((res) => {
          this.initRecord = false;
          this.markTime();
        })
        .catch((err) => {
          let mess = err.response.data.message.toString();

          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },

    startCall() {
      if (this.recharge) {
        const start = moment(this.initCall.begin_time);
        const diff = moment().diff(start, "second");
        this.callElapsed = diff;
        if (this.initCall.timeMarcInit) {
          this.markTimes(this.initCall.timeMarcInit, this.initCall.timeMarcEnd);
        }
      }

      this.intervalCall = setInterval(() => {
        this.callElapsed++;
      }, 1000);
    },
    stopCall() {
      clearInterval(this.intervalCall);
    },

    markTime() {
      if (this.initRecord) {
        this.marks = [];
      }
      this.marks.push({ time: this.callElapsed, percentage: this.callVal });
    },
    markTimes(value1, value2) {
      this.marks = [];
      if (value1) {
        let [hours, minutes, seconds, milliseconds] = value1
          .split(":")
          .map(Number);
        const totalseconds1 =
          hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
        const percentage1 = (100 * totalseconds1) / this.totalTime;
        this.marks.push({ time: this.callElapsed, percentage: percentage1 });
        this.initRecord = true;
      }

      if (value2) {
        this.initRecord = false;
        let [hours2, minutes2, seconds2, milliseconds2] = value2
          .split(":")
          .map(Number);
        const totalseconds2 =
          hours2 * 3600 + minutes2 * 60 + seconds2 + milliseconds2 / 1000;

        const percentage2 = (100 * totalseconds2) / this.totalTime;

        this.marks.push({ time: this.callElapsed, percentage: percentage2 });
      } else {
        this.initRecord = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.timeline {
  width: 100%;
}
.marker {
  position: absolute;
  bottom: 0;
  width: 1px;
  height: 20px;
  background-color: red;
}

.flashed {
  animation-name: flash;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: flash;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
