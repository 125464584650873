import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

const crmConfigurationsModule = {
  namespaced: true,
  state: (): any => {
    return {
      devices: [],
      deviceSelect: null,
      loading: false,
      leads: [],
      totalleads: 0,
      payments: [],
      firstpayments: [],
      totalfirst: 0,
      clockins: [],
      clockdays: [],
      phoneNumbers: [],
      phonesFree: [],
      imageSrc: "",
      totalclockdays: 0,
      overtimes: [],
      totalovertimes: 0,
      presences: [],
      totalxemployee: 0,
      totalpresences: 0,
      quotes: [],
      salesProducts: [],

      systemLogs: [],
      totallogs: 0,
      leadSelect: null,
      amountSelet: null,
      // Loading status for update, list and remove
      loadingDevice: false,
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default crmConfigurationsModule;
