import { RouteConfig } from "vue-router";
import { Permission } from "@/utils/permissions";

const PatientsRoutes: Array<RouteConfig> = [
  {
    path: "/tasks/import_policy_payments",
    name: "Import PolicyPayments",
    component: () =>
      import("@/views/Patients/components/ImportPolicyPayments.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/tasks",
    name: "Task PolicyPayments",
    component: () => import("@/views/Patients/components/TaskPayPoli.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/tasks/logs",
    name: "Task Logs",
    component: () => import("@/views/Patients/components/LogTask.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  {
    path: "/tasks/backups",
    name: "Task Backups",
    component: () => import("@/views/Patients/components/BackupsImport.vue"),
    meta: {
      requiresAdmin: true,
    },
  },
  /*   {
    path: "/clients/member_create/:uuid",
    name: "Members Create",
    component: () => import("@/views/Patients/components/CreateMembers.vue"),
    meta: {
      requiresLogged: true,
    },
  }, */
  /*  {
    path: "/clients/member_edit/:uuid",
    name: "Members Edit",
    component: () => import("@/views/Patients/components/EditMember.vue"),
    meta: {
      requiresLogged: true,
    },
  }, */
  {
    path: "/clients/import",
    name: "Import Client",
    component: () => import("@/views/Patients/components/ImportClients.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "create",
    name: "Create patient",
    component: () => import("@/views/Patients/FormCreate.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "update/:uuid",
    name: "Update patient",
    component: () => import("@/views/Patients/FormUpdate.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "details/:uuid",
    name: "Patient ddetails",
    component: () => import("@/views/Patients/Details.vue"),
    meta: {
      requiresLogged: true,
    },
  },
  {
    path: "",
    name: "Patient list",
    component: () => import("@/views/Patients/Patients.vue"),
    meta: {
      requiresLogged: true,
    },
  },
];

export default PatientsRoutes;
