/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import Patient from "@/models/Patient";
import store from "@/store";
import groupBy from "lodash/groupBy";
import forEach from "lodash/forEach";
import findIndex from "lodash/findIndex";

/*
  {
    id: uniqueId("kanban-card"),
    title: "Select state",
    children: [
      {
        id: uniqueId("kanban-card-item"),
        type: "draggable",
      },
      {
        id: uniqueId("kanban-card-item"),
        type: "draggable",
      },
    ],
  },
*/

export function getPatientsByStatus(state: any): any {
  const groups = [
    {
      id: "NEW",
      title: "New",
      color: "#ab47bc",
      children: [],
    },
    {
      id: "EVALUATION",
      title: "Evaluation",
      color: "#ec407a",
      children: [],
    },
    {
      id: "NO_INTERESTED",
      title: "Not interested",
      color: "#e91e63",
      children: [],
    },
    {
      id: "PENDING",
      title: "Pending",
      color: "#f44336",
      children: [],
    },
    {
      id: "PAYMENT_IN_PROCCESS",
      title: "Payment in Process",
      color: "#26a69a",
      children: [],
    },
    {
      id: "FINISHED_PAYMENT",
      title: "Finished payment",
      color: "#43a047",
      children: [],
    },
    // {
    //   id: "DONE",
    //   title: "Done",
    //   color: "#3f51b5",
    //   children: [],
    // },
  ];
  const patientsGrouped = groupBy(
    (store as any).state.crmMedicFormModule.patients,
    "dashboardStatus"
  );
  // console.log((store as any).state.crmMedicFormModule.patients);

  forEach(patientsGrouped, (patients: Patient[], groupName) => {
    // Para cada paciente solo tomar los datos necesarios
    const basicDataFromPatient = patients.map((patient) => {
      const {
        mailingCity,
        medicalHistory,
        medicalLocation,
        otherMedicalRecord,
        patientDoctor,
        photoAutorationForm,
        surgeryPreOpOrder,
        labs,
        emergencyRelation,
        ekg,
        evaluation,
        ...restPatientData
      } = patient;

      return { id: patient.uuid, type: "draggable", ...restPatientData };
    });
    // Dada la posicion poner los childrens
    const groupIndex = findIndex(groups, (item) => item.id == groupName);

    (groups as any)[groupIndex].children = basicDataFromPatient;
  });

  // console.log(groups);
  return groups;
}
