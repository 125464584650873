import i18n from "@/i18n";
import store from "@/store";
import { MUT_NOTIFICATION } from "@/store/mutations/types";
import { TranslateResult } from "vue-i18n";
import Notification from "./Notification.vue";

const NOTIFICATION_ERROR = "error";
const NOTIFICATION_INFO = "info";
const NOTIFICATION_SUCCESS = "success";

const notificationError = (): void => {
  const msgEn =
    "An error occurred while the operation was in progress, please try again later";
  const msgEs =
    "Ha ocurrido un error mientras se realizaba la operación, intente más tarde";

  store.commit(MUT_NOTIFICATION, {
    body: i18n.locale == "en" ? msgEn : msgEs,
    type: NOTIFICATION_ERROR,
    show: true,
  });
};

const notificationNetworkError = (): void => {
  const titleEn = "Network error";
  const titleEs = "Error de red";

  const msgEn =
    "<p>Check your internet connection.<p><br>If you are using firewalls, proxy or VPN check that it is correctly configured.";
  const msgEs =
    "<p>Compruebe su conexión a internet.</p><br>Si está utilizando firewalls, proxy o VPN verifique que esté correctamente configurado.";

  store.commit(MUT_NOTIFICATION, {
    body: i18n.locale == "en" ? msgEn : msgEs,
    title: i18n.locale == "en" ? titleEn : titleEs,
    type: "error",
    show: true,
  });
};

export function notifyError(body: any, title?: string | TranslateResult): void {
  store.commit(MUT_NOTIFICATION, {
    body: body,
    title: title,
    type: NOTIFICATION_ERROR,
    show: true,
  });
}

export function notifyInfo(
  body: string | TranslateResult,
  title?: string | TranslateResult
): void {
  store.commit(MUT_NOTIFICATION, {
    body: body,
    title: title,
    type: NOTIFICATION_INFO,
    show: true,
  });
}

export function notifySuccess(
  body: string | TranslateResult,
  title?: string | TranslateResult
): void {
  store.commit(MUT_NOTIFICATION, {
    body: body,
    title: title,
    type: NOTIFICATION_SUCCESS,
    show: true,
  });
}

/**
 * Single notification like a snak notification
 * @param body The notification content
 */
export function notifySnack(body: string | TranslateResult): void {
  store.commit(MUT_NOTIFICATION, {
    body: body,
    show: true,
  });
}

export { Notification, notificationError, notificationNetworkError };
