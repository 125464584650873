import * as mutations from "./mutations";
// import * as actions from "./actions";
import * as getters from "./getters";
import * as actions from "./actions";

const crmDashboardModule = {
  namespaced: true,
  state: (): any => {
    return {
      loading: [],
      patientsByStatus: [],
      procedureSelectid: 0,
    };
  },
  mutations: mutations,
  // actions: actions,
  getters: getters,
  actions: actions,
};

export default crmDashboardModule;
