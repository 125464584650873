/**
 * Formatea segundos a una cadena legibe en formato hh:mm:ss
 * Tomado de: https://stackoverflow.com/questions/6312993/javascript-seconds-to-time-string-with-format-hhmmss
 * Adaptado por: Alberto M. Ochoa Fabré<maochoa31415@gmail.com>
 * @param secondsNum Número de segundos
 * @returns string
 */
const prettyTime = function (secondsNum: number): string {
  const hours = Math.floor(secondsNum / 3600);
  const minutes = Math.floor((secondsNum - hours * 3600) / 60);
  const seconds = secondsNum - hours * 3600 - minutes * 60;
  let hoursResult: number | string = "";
  let minutesResult: number | string = "";
  let secondsResult: number | string = "";

  if (hours < 10) {
    hoursResult = "0" + hours;
  } else {
    hoursResult = seconds;
  }

  if (minutes < 10) {
    minutesResult = "0" + minutes;
  } else {
    minutesResult = minutes;
  }

  if (seconds < 10) {
    secondsResult = "0" + seconds;
  } else {
    secondsResult = seconds;
  }

  return hoursResult + ":" + minutesResult + ":" + secondsResult;
};

export default prettyTime;
