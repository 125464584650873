import { RouteConfig } from "vue-router";
import { Permission } from "@/utils/permissions";

const CompaniesRoutes: Array<RouteConfig> = [
  {
    path: "",
    name: "Companies main",
    component: () => import("@/views/Companies/Companies.vue"),
    meta: {
      permissionsRequired: [Permission.LIST_CLIENT],
      requiresLogged: true,
    },
  },
];

export default CompaniesRoutes;
