function formatPhone(phoneValue: string): any {
  let phone = phoneValue;

  for (let i = 0; i < phoneValue.length; i++) {
    const p: any = phoneValue[i];
    if (isNaN(p)) {
      phone = phone.replaceAll(p, "");
    }
  }

  return phone;
}

export default formatPhone;

export function cleanPhoneNumber(number: any) {
  // Eliminar todos los caracteres que no sean dígitos numéricos
  return number.replace(/\D/g, "");
}

export function isPhoneNumber(cadena: string) {
  let c = cleanPhoneNumber(cadena);
  if (c[0] == "1") {
    c = c.slice(1);
  }
  // Expresión regular para verificar si la cadena sigue un formato de número de teléfono típico
  var regex = /^\d{10}$/; // Esto asume un número de 10 dígitos sin ningún otro carácter

  // Comprobar si la cadena coincide con la expresión regular
  return regex.test(c);
}
