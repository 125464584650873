import RecordedCall from "@/models/RecordedCall";

export function contactsOnline(state: any): void {
  return state.contacts.filter((contact: any) => contact.online);
}

export function recordedCallsCompleted(state: any): RecordedCall[] {
  return (state.callList as RecordedCall[]).filter((record: RecordedCall) => {
    return record.status == "completed";
  });
}

export function missedCalls(state: any): RecordedCall[] {
  return (state.callList as RecordedCall[]).filter((record: RecordedCall) => {
    return record.status != "completed";
  });
}

export function recentCalls(state: any): RecordedCall[] {
  return (state.callList as RecordedCall[]).filter((record: RecordedCall) => {
    return record.status == "completed";
  });
}
