import * as mutations from "./mutations";
import * as actions from "./actions";

const crmEmployeeModule = {
  namespaced: true,
  state: (): any => {
    return {
      workers: [],
      agents: [],
      doctors: [],
      coordinators: [],
      totalworkers: 0,
      // Loading status for update, list and remove
      loading: false,
    };
  },
  mutations: mutations,
  actions: actions,
};

export default crmEmployeeModule;
