
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";

export default Vue.extend({
  name: "btn-translate",
  props: {
    label: String,
  },
  methods: {
    setLang(lang: string): void {
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = lang;
    },
  },
});
