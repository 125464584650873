import Worker from "@/models/Worker";
import findIndex from "lodash/findIndex";

export function mutSetWorkers(state: any, workers: Worker[]): void {
  state.workers = workers;
}

export function mutUpdateWorkers(state: any, workers: Worker[]): void {
  state.workers = [...state.workers, ...workers];
}
export function mutagents(state: any, employees: any[]): void {
  state.agents = employees;
}
export function mutDoctors(state: any, employees: any[]): void {
  state.doctors = employees;
}
export function mutCoordinators(state: any, employees: any[]): void {
  state.coordinators = employees;
}

export function mutRemoveWorkers(state: any, uuid: string): void {
  const workerIndex = findIndex(
    state.workers,
    (worker: { uuid: string }) => worker.uuid == uuid
  );

  state.workers.splice(workerIndex, 1);
}

export function mutLoading(state: any, loading: boolean): void {
  state.loading = loading;
}

export function mutTotalWorkers(state: any, val: number): void {
  state.totalworkers = val;
}
