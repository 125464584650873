/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Contact, SMS } from "@/models/Sms";
import store from "@/store";
import uniqBy from "lodash/uniqBy";

export function contacts(state: any): any[] {
  const result: Contact[] = state.messages.map((message: SMS) => {
    return {
      phone:
        message.from == store.state.profile.phone ? message.to : message.from,
      fullname:
        message.from == store.state.profile.phone
          ? message.to
          : message.patient_name,
    };
  });
  const results = result.filter(
    (item: any) => item.phone != store.state.profile.phone
  );
  const removedDoubles = uniqBy(results, "phone");

  return removedDoubles;
}

export function numberMessages(state: any) {
  var numbersms: Map<string, any> = new Map();

  state.messages.map((message: any) => {
    const keyname = message.uuid;
    numbersms.set(keyname, {
      to: message.to,
      from: message.from,
      patientPhone: message.patient_phone,
      employePhone: message.employee_phone,
      message: message.message,
    });
  });

  return numbersms;
}

export function findMessages(state: any) {
  return state.messages;
}

export function messagesPerPatient(state: any) {
  var sms: Map<string, any> = new Map();

  state.messages.map((res: any) => {
    const patname = res.patient_name
      ? res.patient_name
      : res.employee_fullname
      ? res.employee_fullname
      : res.from;

    var phoneNumber = res.from;
    var rol = "PATIENT";
    if (patname == res.patient_name) {
      phoneNumber = res.patient_phone;
    }
    if (patname == res.employee_fullname) {
      rol = res.employee_rol;
      phoneNumber = res.employee_phone;
    }
    if (patname != store.state.profile.fullname) {
      if (sms.has(patname)) {
        var valor: any[] = sms.get(patname).valor;

        valor.push(res);

        sms.set(patname, { phoneNumber, rol, valor });
      } else {
        var valor = new Array<any>();
        valor.push(res);
        sms.set(patname, { phoneNumber, rol, valor });
      }
    }

    /* sms.set(res.patient_name,) */
  });

  return sms;
}
