/* eslint-disable  */
import { getAPI } from "@/api/axios-base";
import { notificationError, notifyError } from "@/components/Notification";
import store from "@/store";
import Worker from "@/models/Worker";
const LIST = "/users/employee/list/";
const CREATE = "/users/employee/create";
const DELETE = "/users/delete/";
const UPDATE = "/users/update/";

export async function actGetEmployees(
  { commit }: any,
  reqrange?: any
): Promise<any> {
  try {
    const rrange = reqrange ? reqrange : { limit: 300, offset: 0 };
    commit("mutLoading", true);
    const responseData = (
      await getAPI(LIST + rrange.limit + "/" + rrange.offset)
    ).data;
    commit("mutTotalWorkers", responseData.count);
    commit("mutSetWorkers", responseData.result);
    const agent = responseData.result.filter(
      (rs: Worker) => rs.rol == "WORKER"
    );
    const docts = responseData.result.filter(
      (rs: Worker) => rs.rol == "DOCTOR"
    );
    commit("mutagents", agent);
    commit("mutDoctors", docts);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actGetPatientDoctors({ commit }: any): Promise<any> {
  commit("mutLoading", true);

  try {
    const responseData = (await getAPI("/users/getDoctors")).data;

    commit("mutDoctors", responseData);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actGetCoordinators({ commit }: any): Promise<any> {
  commit("mutLoading", true);

  try {
    const responseData = (await getAPI("/users/getCoodinators")).data;

    commit("mutCoordinators", responseData);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actRemoveEmployee(
  { commit, dispatch }: any,
  uuid: string
): Promise<any> {
  try {
    commit("mutLoading", true);
    await getAPI.delete(DELETE + uuid);
    await dispatch("actGetEmployees");
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actCreateEmployee(
  { commit, dispatch }: any,
  body: any
): Promise<any> {
  try {
    commit("mutLoading", true);

    let req = body;

    req = cleanNull(req);

    const response = (await getAPI.post(CREATE, req)).data;
    await dispatch("actGetEmployees");
    commit("mutLoading", false);
    return response;
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message.toString();

    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actUpdateWorker(
  { dispatch, commit }: any,
  { uuid, body }: { uuid: string; body: Worker }
): Promise<any> {
  try {
    commit("mutLoading", true);
    await getAPI.post(UPDATE + uuid, body);
    await dispatch("actGetEmployees");
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message.toString();

    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actUpdateWorkerStatus(
  { dispatch }: any,
  { uuid, status }: { uuid: string; status: string }
): Promise<any> {
  try {
    await getAPI.post(UPDATE + uuid, {
      status: status,
    });
    await dispatch("actGetEmployees");
  } catch (error: any) {
    let mess = error.response.data.message.toString();

    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actGetPatientAgents({ commit }: any): Promise<any> {
  commit("mutLoading", true);
  try {
    const responseData = (
      await getAPI.post("/users/filterUsers", {
        rol: "WORKER",
        limit: 200,
        offset: 0,
      })
    ).data.result;
    commit("mutagents", responseData);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actGeneratePin({ commit }: any, req: any) {
  commit("mutLoading", true);
  try {
    const responseData = (await getAPI.put("/users/generate-ping/" + req)).data;

    commit("mutLoading", false);
    return responseData;
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

function cleanNull(obj: any) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
}
