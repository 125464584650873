import RecordedCall from "@/models/RecordedCall";
import Worker from "@/models/Worker";

export function mutOnlineWorkers(state: any, onlineWorkers: Worker[]): void {
  state.onlineWorkers = onlineWorkers;
}

export function mutCallList(state: any, callList: RecordedCall[]): void {
  state.callList = callList;
}
export function mutMissList(state: any, callList: RecordedCall[]): void {
  state.missList = callList;
}
export function mutRecList(state: any, callList: RecordedCall[]): void {
  state.recordList = callList;
}
export function mutCallsLead(state: any, callList: RecordedCall[]): void {
  state.callsLead = callList;
}

export function mutLoading(state: any, loading: boolean): void {
  state.loading = loading;
}
export function mutCallProgress(state: any, value: boolean): void {
  state.callinprogress = value;
}
export function mutLoadingFilter(state: any, loading: boolean): void {
  state.loadingfilter = loading;
}
export function mutDialogmakeCall(state: any, loading: boolean): void {
  state.dialogmakeCall = loading;
}
export function mutTotalMiss(state: any, totalmiss: number): void {
  state.totalmiss = totalmiss;
}
export function mutTotalRec(state: any, totalrecord: number): void {
  state.totalrecord = totalrecord;
}
export function mutTotalCall(state: any, totalcalls: number): void {
  state.totalcalls = totalcalls;
}
export function mutRequest(state: any, value: any): void {
  state.requestC = value;
}
export function mutView(state: any, value: string): void {
  state.view = value;
}
export function mutFilter(state: any, value: boolean): void {
  state.filter = value;
}
