export type Rol =
  | "WORKER"
  | "SUPER"
  | "ADMIN"
  | "DOCTOR"
  | "SUPERVISOR"
  | "AGENT";
export type Status = "Idle" | "Offline" | "Busy" | "Reserved";
const RolLabel = {
  WORKER: "Surgical Coordinator",
  SUPER: "Super Administrator",
  ADMIN: "Administrator",
  DOCTOR: "Doctor",
  SUPERVISOR: "Supervisor",
};

function rolIcon(rol: Rol): string {
  let icon = "";

  switch (rol) {
    case "WORKER":
      icon = "mdi-face-agent";
      break;
    case "AGENT":
      icon = "mdi-face-agent";
      break;
    case "SUPER":
      icon = "mdi-account-tie";
      break;
    case "ADMIN":
      icon = "mdi-account-star";
      break;
    case "DOCTOR":
      icon = "mdi-doctor";
      break;
    case "SUPERVISOR":
      icon = "mdi-book-account";
      break;
    default:
      icon = "mdi-account";
      break;
  }

  return icon;
}

function rolColor(rol: Rol): string {
  let color = "";

  switch (rol) {
    case "WORKER":
      color = "#f4511e";
      break;
    case "AGENT":
      color = "#75260E";
      break;
    case "SUPER":
      color = "#9c27b0";
      break;
    case "ADMIN":
      color = "#3f51b5";
      break;
    case "DOCTOR":
      color = "#43a047";
      break;
    case "SUPERVISOR":
      color = "#009688";
      break;
    default:
      color = "#039be5";
  }

  return color;
}

function statusColor(status: Status): string {
  let result = "";

  switch (status) {
    case "Idle":
      result = "#4caf50";
      break;
    case "Offline":
      result = "#f44336";
      break;
    case "Busy":
      result = "#009688";
      break;
    case "Reserved":
      result = "#00897b";
      break;
  }

  return result;
}

export { rolIcon, rolColor, statusColor, RolLabel };
