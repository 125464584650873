
/**
 * To set a state for show the UI to forwarding
 */
import Vue from "vue";

export default Vue.extend({
  name: "btn-show-forwarding",
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      status: false,
    };
  },
});
