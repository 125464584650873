
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";

export default Vue.extend({
  name: "btn-call-in-progress",
  // inject: ["callCenterProvider"],
  computed: {
    show(): boolean {
      let result = false;
      /* 
      if (
        (this as any).callCenterProvider.incomingCallConnection != null ||
        (this as any).callCenterProvider.incomingCallConnection != undefined
      ) {
        result =
          (this as any).callCenterProvider.incomingCallConnection.status() ==
            "open" ||
          (this as any).callCenterProvider.incomingCallConnection.status() ==
            "pending" ||
          ((this as any).callCenterProvider.incomingCallConnection.status() ==
            "ringing" &&
            (this as any).callCenterProvider.incomingCall == false);
      } */

      return result;
    },
  },
});
