
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */

import Vue from "vue";
import CrmEmojiPicker from "../buttons/CrmEmojiPicker.vue";
import rules from "@/components/account/rules";
import CrmFileSend from "../buttons/CrmFileSend.vue";
import CrmImageSend from "../buttons/CrmImageSend.vue";
import { mapActions, mapState } from "vuex";
import { notifyError } from "@/components/Notification";
import containLetter from "@/utils/containLetter";
export default Vue.extend({
  components: { CrmEmojiPicker, CrmFileSend, CrmImageSend },
  name: "message-input",
  props: {
    value: String,
    loading: Boolean,
    global: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      options: false,
      loadingSendMessage: false,
      row: 1,
      sms: {
        number: "",
        message: "",
        // replyTo: "+17869461441",
      },
      rules,
    };
  },
  computed: {
    ...mapState("crmSMSModule", ["contactSelect"]),
  },
  watch: {
    value(val) {
      //console.log(this.row);
    },
    contactSelect: {
      handler() {
        if (this.contactSelect != null) {
          this.sms.number = this.contactSelect.number;
          this.sms.message = "";
        }
      },
    },
  },
  methods: {
    ...mapActions("crmSMSModule", [
      "actSendMessage",
      "actListMessages",
      "actReadMessages",
    ]),
    showOptions() {
      const moreBtn = (this.$refs.moreBtn as any).$el;

      this.options = !this.options;
      (this as any).$anime({
        targets: moreBtn,
        rotate: this.options ? "45deg" : "0deg",
        duration: 150,
        easing: "cubicBezier(.42,0,.6,.99)",
      });
    },
    insertEmoji(emoji: string) {
      const messageInput = (
        (this as any).$refs.messageInput.$el.childNodes as HTMLElement[]
      )[0].childNodes[0].childNodes[1].childNodes[0] as HTMLInputElement;

      const cursorPosition = messageInput.selectionEnd;
      const start = this.sms.message.substring(
        0,
        messageInput.selectionStart as any
      );
      const end = this.sms.message.substring(
        messageInput.selectionStart as any
      );

      this.sms.message = start + emoji + end;

      messageInput.focus();
      this.$nextTick(() => {
        messageInput.selectionEnd = (cursorPosition as any) + emoji.length;
      });
    },

    insertImages(images: any) {
      const messageInput = (
        (this as any).$refs.messageInput.$el.childNodes as HTMLElement[]
      )[0].childNodes[0].childNodes[1].childNodes[0] as HTMLInputElement;
      const cursorPosition = messageInput.selectionEnd;
      const start = this.sms.message.substring(
        0,
        messageInput.selectionStart as any
      );
      const end = this.sms.message.substring(
        messageInput.selectionStart as any
      );

      this.sms.message = start + images + end;
      messageInput.focus();
      this.$nextTick(() => {
        messageInput.selectionEnd = (cursorPosition as any) + images.length;
      });
    },

    sendMessage(): void {
      this.loadingSendMessage = true;
      const mess = this.sms.message;

      if (
        this.sms.message != "" &&
        this.sms.message != null &&
        this.sms.message != undefined &&
        this.contactSelect != undefined &&
        this.contactSelect != null
      ) {
        this.sms.number = this.contactSelect.number;
        this.sms.message = "";
        this.actSendMessage({ message: mess, number: this.sms.number })
          .then(() => {
            this.loadingSendMessage = false;
            this.sms.message = "";
            this.actListMessages();
            this.$emit("send-message");
          })
          .catch((error) => {
            this.loadingSendMessage = false;
            this.sms.message = "";
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
      this.loadingSendMessage = false;
    },
  },
});
