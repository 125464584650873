import { RouteConfig } from "vue-router";

const CrmNotificationsRoutes: Array<RouteConfig> = [
  {
    path: "",
    name: "Notifications",
    component: () => import("@/views/Reminder/Calendar/Calendar.vue"),
    meta: {
      requiresLogged: true,
    },
    props: true,
  },
];

export default CrmNotificationsRoutes;
