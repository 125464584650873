/**
 * @description For components that need use v-model="variable"
 */

import Vue from "vue";

const Input = Vue.extend({
  props: {
    /**
     * Define the number or content to show in badge.
     * The type can be 'Number' or 'String' for more flexibility
     */
    value: {
      type: [Number, String],
    },
    placeholder: String,
    inputProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      mValue: "",
    };
  },
  methods: {
    onInput(): void {
      this.$emit("input", this.mValue);
    },
  },
});

export default Input;
