
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import RecordCall from "@/views/CallCenter/RecordCall.vue";

export default Vue.extend({
  components: { RecordCall },
  name: "btn-voice-email",
  data() {
    return {
      status: false,
      recordProgress: 0,
      processId: 0,
      recording: false,
      pausedRecord: false,
    };
  },
});
