import Vue from "vue";
import prettyTime from "@/utils/prettyTime";
import { mapMutations } from "vuex";

const Call = Vue.extend({
  data() {
    return {
      timeConnected: 0,
      timeConnectedId: 0,
      muteCall: false,
    };
  },
  computed: {
    formatTimeConnected(): string {
      return prettyTime(this.timeConnected);
    },
  },
  watch: {
    muteCall(value: boolean) {
      this.mutmutedCall(value);
      /*  (this as any).callCenterProvider.twilioDeviceProvider
        .activeConnection()
        .mute(value); */
    },
    timeConnected(val) {
      if (val != null && val != undefined) {
        this.mutcallTime(this.formatTimeConnected);
      }
    },
  },
  methods: {
    ...mapMutations(["mutcallTime", "mutmutedCall"]),
    startConnectionCount(): void {
      (this.timeConnectedId as any) = setInterval(() => {
        this.timeConnected = this.timeConnected + 1;
      }, 1000);
    },
    stopConnectionCount(): void {
      this.timeConnected = 0;
      clearTimeout(this.timeConnectedId);
    },
  },
});

export default Call;
