import * as mutations from "./mutations";
import * as actions from "./actions";
import { fastRandom } from "@/utils/fastRamdom";

const crmMedicFormModule = {
  namespaced: true,
  state: (): any => {
    return {
      states: [],
      loadingStates: false,
      educationLevels: [],
      loadingEducationLevels: false,
      medicalLocations: [],
      loadingMedicalLocations: false,
      patients: [],
      reqrange: { limit: 100, offset: 0 },
      havecard: false,
      epaycard: false,
      unreadDoc: false,
      countUnread: 0,
      loadingPatiens: false,
      doctors: [],
      agents: [],
      loadingDoctors: false,
      loading: false,
      totalpatient: 0,
      showDetails: false,
      patientDetail: null,
      quotes: [],
      salesAccessories: [],
      procedure: null,
    };
  },
  mutations: mutations,
  actions: actions,
};

export default crmMedicFormModule;
