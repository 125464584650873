/* eslint-disable */
import { getAPI } from "@/api/axios-base";
import { notificationError, notifyError } from "@/components/Notification";
import RecordedCall from "@/models/RecordedCall";
import store from "@/store";
import formatPhone from "@/utils/formatPhone";

export async function actOnlineWorkers(
  { commit }: any,
  companyId: string
): Promise<any> {
  try {
    const responseData = (await getAPI("/users/getWorkerOnline/" + companyId))
      .data;

    commit("mutOnlineWorkers", responseData);
  } catch (error: any) {
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actGetCalls({ commit }: any, req: any): Promise<any> {
  let rrange = req;

  rrange = cleanNull(rrange);
  try {
    commit("mutLoading", true);
    const responseData = (
      await getAPI.post("/twiliocloud/getregisteredcalls", rrange)
    ).data;

    commit("mutTotalCall", responseData.count);

    commit("mutCallList", responseData.result);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actGetCallsMis({ commit }: any, req: any): Promise<any> {
  let rrange = req ? req : { range: { limit: 15, offset: 0 } };

  rrange = cleanNull(rrange);

  try {
    commit("mutLoading", true);
    const responseData = (
      await getAPI.post("/twiliocloud/getregisteredcalls", rrange)
    ).data;

    commit("mutTotalMiss", responseData.count);
    commit("mutMissList", responseData.result);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
export async function actGetCallsRec({ commit }: any, req: any): Promise<any> {
  let rrange = req ? req : { range: { limit: 15, offset: 0 } };

  rrange = cleanNull(rrange);
  try {
    commit("mutLoading", true);
    const responseData = (
      await getAPI.post("/twiliocloud/getregisteredcalls", rrange)
    ).data;
    /* const resres = responseData.result.map((res: RecordedCall) => {
      res.from = res.from.replace("-", "");
      res.to = res.to.replace("-", "");
      res.from = res.from.replace("(", "");
      res.to = res.to.replace("(", "");
      res.from = res.from.replace(")", "");
      res.to = res.to.replace(")", "");
      res.from = res.from.replace(" ", "");
      res.to = res.to.replace(" ", "");
      return res;
    }) */
    commit("mutTotalRec", responseData.count);
    commit("mutRecList", responseData.result);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actFilterCalls({ commit }: any, req: any): Promise<any> {
  try {
    commit("mutLoadingFilter", true);
    const body = req;

    let num = body.whateverNumber;
    if (num.includes("+1")) {
      num = num.replace("+1", "");
    }
    num = num.replaceAll("\t", "");
    num = num.replaceAll(" ", "");
    num = formatPhone(num);
    num = "+1" + num;
    body.whateverNumber = num;

    const responseData = (await getAPI.post("/twiliocloud/filterCall", body))
      .data;
    commit("mutCallsLead", responseData.result);

    commit("mutLoadingFilter", false);
  } catch (error: any) {
    commit("mutLoadingFilter", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actRemoveRecordedCall(
  { dispatch, commit }: any,
  callId: string
): Promise<any> {
  commit("mutLoading", true);
  await getAPI.post("/twiliocloud/deleteCallRecord/" + callId);

  dispatch("actRecordedCalls");
  commit("mutLoading", false);
}

function cleanNull(obj: any) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}
