import Vue from "vue";

Vue.filter("crmcurrency", function (val: number, decimals = 2): string {
  // Expresion regular para numeros con un cierto numero de decimales o mas
  const numeroRegexp = new RegExp("\\d\\.(\\d){" + decimals + ",}");

  // Ya que el numero tiene el numero de decimales requeridos o mas, se realiza el redondeo
  if (numeroRegexp.test(val.toString())) {
    return "$" + Number(val.toFixed(decimals));
  } else {
    // En valores muy bajos, se comprueba si el numero es 0 (con el redondeo deseado), si no lo es se devuelve el numero otra vez.
    return "$" + (Number(val.toFixed(decimals)) === 0 ? 0 : val);
  }
});
