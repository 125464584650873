/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import i18n from "@/i18n";
import moment from "moment";

/**
 * Create a pretty date: Hace 2 dias, Justo ahora, etc...
 *
 * @author Alberto Manuel Ochoa Fabré <maochoa31415@gmail.com>
 * @param {String|Number} dateFormat Date in string format or number for milliseconds format
 * @return String
 * @example
 * ```javascript
 * // The result depend of translation
 *  longTime('2020-09-04T20:07:39.421Z') //=> Hace 2 minutos
 * ```
 */
function longTime(dateFormat: string): string {
  if (process.env.NODE_ENV === "development") {
    if (!dateFormat) {
      console.error("Param 'dateFormat' missing");
      return "";
    }
  }

  const momentFrom = moment(dateFormat);
  const thisday = moment();
  const dias = moment().diff(momentFrom, "day");
  const date = new Date(dateFormat);
  const now = new Date();
  const yearsDif = thisday.diff(momentFrom, "year");
  const monthsDif = thisday.diff(momentFrom, "month");
  const daysDif = thisday.diff(momentFrom, "day");
  const hoursDif = thisday.diff(momentFrom, "hour");
  const minutesDif = thisday.diff(momentFrom, "minute");
  const secondsDif = thisday.diff(momentFrom, "second");

  if (yearsDif > 0) {
    return i18n.tc("time_ago.years", yearsDif, { count: yearsDif });
  }

  if (monthsDif > 0) {
    if (daysDif < 30 && monthsDif == 1) {
      return i18n.tc("time_ago.days", daysDif, { count: dias });
    }
    return i18n.tc("time_ago.months", monthsDif, { count: monthsDif });
  }

  if (daysDif > 0) {
    return i18n.tc("time_ago.days", daysDif, { count: daysDif });
  }

  if (hoursDif > 0) {
    return i18n.tc("time_ago.hours", hoursDif, { count: hoursDif });
  }

  if (minutesDif > 0) {
    return i18n.tc("time_ago.minutes", minutesDif, {
      count: minutesDif,
    });
  }

  // The last is seconds
  return i18n.tc("time_ago.seconds", secondsDif, { count: secondsDif });
}

export default longTime;
