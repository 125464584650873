import * as mutations from "./mutations";
import * as actions from "./actions";

const crmAppointmentsModule = {
  namespaced: true,
  state: (): any => {
    return {
      select: {
        type: "",
        nomId: null,
      },

      loading: false,
      appointments: [],
      // The current date
      date: new Date().toISOString().slice(0, 10),
    };
  },
  mutations: mutations,
  actions: actions,
};

export default crmAppointmentsModule;
