
import { getAPI } from "@/api/axios-base";
import Vue from "vue";
import FieldPassword from "../account/FieldPassword.vue";
import { notificationError, notifyInfo } from "../Notification";

export default Vue.extend({
  components: { FieldPassword },
  name: "btn-change-password",
  data() {
    return {
      dialog: false,
      passwordRetry: "",
      loading: false,
      formValid: false,
      form: {
        password: "",
      },
    };
  },
  computed: {
    passwordIsRight(): boolean {
      const { passwordRetry, form } = this;
      const same = passwordRetry == form.password;
      const notEmpty = passwordRetry.trim() != "" && form.password.trim() != "";
      const maxLength = passwordRetry.length >= 8 && form.password.length >= 8;

      return same && notEmpty && maxLength;
    },
    samePassword(): boolean {
      return this.passwordRetry.trim() === this.form.password.trim();
    },
    disableUpdate(): boolean {
      return (
        (this.passwordIsRight && this.samePassword && this.formValid) == false
      );
    },
  },
  methods: {
    resetForm(): void {
      this.passwordRetry = "";
      this.form.password = "";

      if (this.$refs.form) {
        (this as any).$refs.form.resetValidation();
      }
    },
    update() {
      this.loading = true;
      getAPI
        .post("/users/updatePassword", this.form)
        .then(() => {
          this.loading = false;
          this.close();
          notifyInfo("Your password have been updated successfully");
        })
        .catch(() => {
          this.loading = false;
          notificationError();
          this.close();
        });
    },
    close() {
      this.dialog = false;
    },
  },
});
