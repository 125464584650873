/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Appointment from "@/models/Appointment";
import Doctor from "@/models/Doctor";
import Patient from "@/models/Patient";

export function mutLoading(state: any, loading: boolean): void {
  state.loading = loading;
}

interface ResponseData {
  partial: Appointment;
  doctor: Doctor;
  patient: Patient;
  procedure: any;
}

const appointmentColors = {
  TREATMENT: "#1976d2",
  EVALUATION: "#388e3c",
  FOLLOWUP: "#00796b",
  SURGERYORnone: "#607d8b",
  SURGERYORno1: "#d81b60",
  SURGERYORno2: "#ec407a",
};

export function mutAppointmets(state: any, appointments: ResponseData[]): void {
  const normalized = appointments.map((response: ResponseData) => ({
    name: response.partial.dateName,
    start: new Date(response.partial.initialTime),
    end: new Date(response.partial.endTime),
    color: appointmentColors[response.partial.type],
    timed: true,
    data: {
      doctor: response.doctor,
      patient: response.patient,
      partial: {
        uuid: response.partial.uuid,
        note: response.partial.note,
        treatmentType: response.partial.treatmentType,
        surgeryType: response.partial.surgeryType,
      },
      procedure: response.procedure,
      type: response.partial.type,
    },
  }));

  state.appointments = normalized;
}

export function mutDate(state: any, date: string): void {
  state.date = date;
}
export function mutSelect(state: any, select: any): void {
  state.select = select;
}
