
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  name: "input-companies",
  props: {
    value: String,
    selectProps: {
      type: Object,
      default: () => ({
        outlined: true,
      }),
    },
  },
  data() {
    return {
      companyUuid: "",
    };
  },
  watch: {
    value(val: string) {
      this.companyUuid = val;
    },
  },
  computed: {
    ...mapState("crmCompaniesModule", ["loading", "companies"]),
  },
  mounted() {
    this.actGetCompanies();
  },
  methods: {
    ...mapActions("crmCompaniesModule", ["actGetCompanies"]),
  },
});
