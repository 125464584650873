<template>
  <v-menu offset-y :close-on-content-click="false" min-width="160">
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        class="rounded-circle"
        :color="connected == true ? 'green' : '#ccc'"
        offset-x="13"
        offset-y="18"
        :value="true"
        v-ripple
        bordered
        bottom
        dot
      >
        <v-avatar color="blue" size="36" v-on="on" v-bind="attrs" v-ripple>
          <img v-if="profile.avatar" :src="avatar" height="52px" width="auto" />
          <span v-else class="white--text">
            {{
              profile.username != undefined ? profile.username.slice(0, 2) : ""
            }}
          </span>
        </v-avatar>
      </v-badge>
    </template>

    <v-sheet class="d-flex flex-column">
      <v-list nav dense>
        <!-- <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("btnUser.profile") }}</v-list-item-title>
        </v-list-item> -->
        <btn-change-password />
        <v-list-item @click="goprofile">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("btnUser.profile") }}</v-list-item-title>
        </v-list-item>
        <!--  <v-list-item @click="gocontacts">
          <v-list-item-icon>
            <v-icon>mdi-contacts</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("contacts") }}</v-list-item-title>
        </v-list-item> -->
        <v-list-item @click="logout" link>
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t("btnUser.loguot") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-menu>
</template>

<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { apiBaseUrl } from "@/enviorment";

import axios, { AxiosError } from "axios";

import BtnChangePassword from "./BtnChangePassword.vue";

import { mapMutations, mapState } from "vuex";

export default {
  name: "btn-user",
  // inject: ["callCenterProvider"],
  components: {
    BtnChangePassword,
  },
  data() {
    return {
      loadingImg: false,
    };
  },

  methods: {
    ...mapMutations(["updateAvatar"]),
    logout() {
      // this.callCenterProvider.twilioDeviceProvider.destroy();
      this.$store.dispatch("logoutUser");
    },

    goprofile() {
      this.$router.push("/profile");
    },
    gocontacts() {
      this.$router.push("/contacts");
    },

    getAvatar() {
      const token = this.$store.state.accessToken;
      const auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "application/pdf",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "application/pdf",
            };
      this.loadingImg = true;

      const url = `${apiBaseUrl}/users/avatar/${this.profile.avatar}`;
      if (this.profile.avatar != null) {
        axios({
          method: "get",
          url: url,
          responseType: "blob",
          headers: auth,
        }).then((response) => {
          const buffer = new Blob([response.data]);
          const file_path = URL.createObjectURL(buffer);

          this.updateAvatar(file_path);

          this.loadingImg = false;
        });
      }
    },
  },
  mounted() {
    if (this.profile.avatar != null) {
      this.getAvatar();
    }
  },
  computed: {
    ...mapState(["profile", "avatar", "connected"]),
  },
};
</script>
