var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-snackbar',{staticClass:"incomming-call-dialog",attrs:{"color":"blue-grey darken-4","elevation":"15","rounded":"xl","timeout":-1},model:{value:(_vm.m_snackbar),callback:function ($$v) {_vm.m_snackbar=$$v},expression:"m_snackbar"}},[_c('v-layout',{attrs:{"column":"","justify-space-between":""}},[_c('v-layout',{attrs:{"align-center":"","justify-space-between":""}},[_c('div',{class:_vm.callHold
            ? 'body-1 font-weight-bold text-center orange--text py-4 px-3'
            : 'body-1 font-weight-bold text-center green--text py-4 px-3'},[_vm._v(" "+_vm._s(_vm.callHold ? _vm.$t("Hold", { time: _vm.formatTimeConnected }) : _vm.$t("connected", { time: _vm.formatTimeConnected }))+" ")]),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.m_snackbar = false}}},[_c('v-icon',[_vm._v("mdi-window-close")])],1)],1),_vm._v(" "+_vm._s(/* Usuario con quien se esta hablando */)+" "),_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('div',{staticClass:"text-body-2 text--secondary"})])],1),_c('v-expand-transition',[(_vm.showForwarding && _vm.acceptedCall && _vm.agents.length > 0)?_c('v-layout',{staticStyle:{"max-height":"240px"},attrs:{"column":""}},[_c('div',{staticClass:"text-body-1 mb-2 font-weight-bold ml-4 grey--text darken-4"},[_vm._v(" "+_vm._s(_vm.$t("forwardingTo"))+" ")]),_c('v-text-field',{staticClass:"mx-4 mb-2",attrs:{"placeholder":_vm.$t('labelSearch'),"outlined":"","hide-details":"","dense":"","autofocus":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-list',{staticClass:"overflow-y-auto rounded-lg",attrs:{"color":"blue-grey darken-4","nav":""}},[_vm._l((_vm.filterAgents),function(agent,index){return [_c('v-list-item',{key:index,staticClass:"px-2",attrs:{"link":""},on:{"click":function($event){return _vm.forwardTo(agent.username)}}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_vm._v(_vm._s(agent.fullname))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(agent.phone))])],1)],1),(index != _vm.agents.length - 1)?_c('v-divider',{key:'d-' + index,attrs:{"inset":""}}):_vm._e()]})],2)],1):_vm._e()],1),_c('v-layout',{staticClass:"mt-2",attrs:{"align-center":"","justify-space-around":""}},[(!_vm.acceptedCall)?_c('v-btn',{staticClass:"green",class:{
        'nt-notification-success': _vm.takenCall == false,
      },attrs:{"large":_vm.takenCall == false,"icon":""},on:{"click":_vm.acceptCall}},[_c('v-icon',[_vm._v("mdi-phone")])],1):_vm._e(),_c('v-scale-transition',[(_vm.acceptedCall)?_c('btn-on-off-microphone',{model:{value:(_vm.muteCall),callback:function ($$v) {_vm.muteCall=$$v},expression:"muteCall"}}):_vm._e()],1),(_vm.acceptedCall)?[_c('v-scale-transition',[_c('v-btn',{attrs:{"icon":"","color":"warning"},on:{"click":_vm.holdCall}},[_c('v-icon',[_vm._v(_vm._s(_vm.callHold ? "mdi-play" : "mdi-pause"))])],1)],1)]:_vm._e(),_c('v-scale-transition',[(_vm.loadingAgents)?_c('v-progress-circular',{attrs:{"color":"primary","size":"24"}}):_vm._e(),(_vm.acceptedCall && _vm.agents.length > 0 && _vm.loadingAgents == false)?_c('btn-show-forwarding',{model:{value:(_vm.showForwarding),callback:function ($$v) {_vm.showForwarding=$$v},expression:"showForwarding"}}):_vm._e()],1),_c('v-btn',{staticClass:"red",class:{
        'nt-notification-danger': _vm.takenCall,
      },attrs:{"large":_vm.takenCall,"dark":"","icon":""},on:{"click":_vm.hangup}},[_c('v-icon',[_vm._v("mdi-phone-hangup")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }