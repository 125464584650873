const crmAccountModule = {
  namespaced: true,
  // state: (): any => {
  //   return {
  //     username: "super",
  //     uuid: "3686eb7e-a837-488c-8f7f-1a0f4881595b",
  //     rol: "SUPER",
  //     email: "super@gmail.com",
  //     avatar: null,
  //     fullname: "Super Admin",
  //     agentStatus: "ONLINE",
  //     phone: "+1786-755-1738",
  //     company: null,
  //   };
  // },
  getters: {

  },
};

export default crmAccountModule;
