import * as mutations from "./mutations";
import * as actions from "./actions";

const crmSettingsModule = {
  namespaced: true,
  state: (): any => {
    return {
      loading: false,
      // procedureSubtypes: [],
      nomenclators: [],
      //treatments: [],
      //surgerys: [],
      //accessories: [],
      references: [],
      companies: [],
      tags: [],
      appointmenttypes: [],
      loadingRefer: false,
      loadingTags: false,
    };
  },
  mutations: mutations,
  actions: actions,
};

export default crmSettingsModule;
