import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import store from "@/store/index";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },

    themes: {
      light: {
        primary: store.state.dataCRM.themes.light.primary,
      },
      dark: store.state.dataCRM.themes.light,
    },
  },
});

export default vuetify;
