import { RouteConfig } from "vue-router";

const PdfSignRoutes: Array<RouteConfig> = [
  {
    path: "/patient/checkstep1/:contractUuid",
    name: "Step1",
    components: {
      AppSteps: () =>
        import(/* webpackChunkName: "step1" */ "@/views/PdfSign/Step1.vue"),
    },
    meta: {
      requiresLogged: false,
    },
  },
  {
    path: "/patient/checkstep2/:uuid",
    name: "Step2",
    components: {
      AppSteps: () =>
        import(/* webpackChunkName: "step2" */ "@/views/PdfSign/Step2.vue"),
    },
    meta: {
      requiresLogged: false,
    },
  },
  {
    path: "/patient/checkstep3/:uuid",
    name: "Step3",
    components: {
      AppSteps: () =>
        import(/* webpackChunkName: "step3" */ "@/views/PdfSign/Step3.vue"),
    },
    meta: {
      requiresLogged: false,
    },
  },
];

export default PdfSignRoutes;
