import * as mutations from "./mutations";
import * as actions from "./actions";

const crmCompaniesModule = {
  namespaced: true,
  state: (): any => {
    return {
      companies: [],
      loading: false,
    };
  },
  mutations: mutations,
  actions: actions,
};

export default crmCompaniesModule;
