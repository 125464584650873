
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { Dialer } from "@/components/Dialer";

export default Vue.extend({
  name: "btn-dialer",
  components: {
    Dialer
  },
  props: {
    btnProps: {
      type: Object,
      default: () => ({})
    }
  }
});
