
/* eslint-disable */
import Vue from "vue";

import BtnOnOffMicrophone from "@/components/buttons/BtnOnOffMicrophone.vue";
import { BtnShowForwarding } from "@/components/buttons";

import { Call } from "@/mixins";
import { Connection } from "twilio-client";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySnack,
} from "@/components/Notification";
import { getAPI } from "@/api/axios-base";
import { AxiosResponse } from "axios";
import Worker from "@/models/Worker";
import { isDevelop } from "@/enviorment";

export default Vue.extend({
  name: "dialog-incomming-call",
  mixins: [Call],
  //inject: ["callCenterProvider"],
  components: { BtnOnOffMicrophone, BtnShowForwarding },
  props: {
    value: Boolean,
  },
  data() {
    return {
      callHold: false,
      resHold: null,
      m_snackbar: false,
      takenCall: false,
      acceptedCall: false,
      search: "",
      showForwarding: false,
      agents: [],
      loadingAgents: false,
    };
  },
  computed: {
    filterAgents(): Worker[] {
      const criteria = this.search.toLowerCase().trim();

      if (criteria == "") return this.agents;

      const agents = this.agents.filter((agent: Worker): boolean => {
        return (
          agent.fullname.toLowerCase().includes(criteria) ||
          agent.phone.toLowerCase().includes(criteria)
        );
      });

      return agents;
    },
  },
  watch: {
    value(val: boolean): void {
      this.m_snackbar = val;

      if (val) {
        (this as any).muteCall = false;
        /*  if ((this as any).callCenterProvider.incomingCallConnection != null) {
          (this as any).callCenterProvider.incomingCallConnection.on(
            "disconnect",
            this.onCancel
          );
        } */
      }
    },
    m_snackbar(val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    /*  (this as any).callCenterProvider.twilioDeviceProvider.on(
      "cancel",
      this.onCancel
    ); */
  },
  methods: {
    holdCall() {
      if ((this as any).callHold) {
        const conferenceSid = (this as any).resHold.conferenceSid;
        const customerSid = (this as any).resHold.customerSid;
        /*   getAPI
          .put("/twiliocloud/unhold/" + conferenceSid + "/" + customerSid)
          .then((res) => {
            (this as any).callHold = false;
            this.resHold = res.data;
            (this as any).stopConnectionCount();
            (this as any).startConnectionCount();
          })
          .catch((error) => {
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          }); */
      } else {
        /*  const callsid = (this as any).callCenterProvider.incomingCallConnection
          .parameters.CallSid;
        getAPI
          .put("/twiliocloud/hold/" + callsid)
          .then((res) => {
            (this as any).callHold = true;
            (this as any).resHold = res.data;
            (this as any).stopConnectionCount();
            (this as any).startConnectionCount();
          })
          .catch((error) => {
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          }); */
      }
    },

    acceptCall(): void {
      /*  (this as any).callCenterProvider.incomingCallConnection.accept();
      (this as any).stopConnectionCount();
      (this as any).startConnectionCount(); */
      this.getAgents();
      this.takenCall = true;
      this.acceptedCall = true;
    },
    hangup(): void {
      // Stop the time connected counter
      /* (this as any).stopConnectionCount();
      const connectionStatus = (
        this as any
      ).callCenterProvider.incomingCallConnection.status();

      console.log("Connections status: ", connectionStatus);

      if (connectionStatus == "pending") {
        (this as any).callCenterProvider.incomingCallConnection.reject();
        (this as any).callCenterProvider.twilioDeviceProvider.disconnectAll();
      } else if (connectionStatus == "open") {
        (this as any).callCenterProvider.twilioDeviceProvider.disconnectAll();
      } else {
        (this as any).callCenterProvider.twilioDeviceProvider.disconnectAll();
      }
 */
      this.m_snackbar = false;
      this.takenCall = false;
      this.acceptedCall = false;
      this.showForwarding = false;
      this.$emit("hangup", this.m_snackbar);
      this.$emit("input", this.m_snackbar);
    },
    // La llamada entrante fue colgada
    onCancel(connection: Connection) {
      // isDevelop && console.log("onCancel", connection);
      this.m_snackbar = false;
      this.takenCall = false;
      this.acceptedCall = false;
      this.$emit("hangup", this.m_snackbar);
      this.$emit("input", this.m_snackbar);
      (this as any).stopConnectionCount();

      notifyInfo(this.$t("crmCallCenter.notificationCallFinish"));
    },

    forwardTo(phone: string) {
      /*  callerId: (this as any).callCenterProvider.incomingCallConnection
            .parameters.From, */
      /*  getAPI
        .post("/twiliocloud/forwardCall", {
          forwardTo: phone,
          callId: (this as any).callCenterProvider.incomingCallConnection
            .parameters.CallSid,
        })
        .then(() => {
          this.m_snackbar = false;
          this.$emit("input", this.m_snackbar);
        })
        .catch(() => {
          notificationError();
        }); */
    },
    getAgents(): void {
      this.loadingAgents = true;
      getAPI("/users/neighboursAgents").then((response: AxiosResponse) => {
        const responseData = response.data;

        if (responseData.length == 0) {
          notifySnack("No agents available online");
        }

        this.loadingAgents = false;
        this.agents = responseData;
      });
    },
  },
});
