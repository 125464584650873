
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { VEmojiPicker } from "v-emoji-picker";
import { Emoji } from "v-emoji-picker/lib/models/Emoji";

export default Vue.extend({
  name: "crm-emoji-picker",
  components: {
    VEmojiPicker,
  },
  data() {
    return {
      search: "",
      showPicker: false,
    };
  },
  methods: {
    selectEmoji(event: Emoji): void {
      this.$emit("emoji-select", event.data);
    },
  },
});
