import { fastRandom } from "@/utils/fastRamdom";
import * as actions from "./actions";
import * as mutations from "./mutations";
import * as getters from "./getters";

// Mock data
const contactsList = (() => {
  const result = [];
  const contactsCount = 10;

  for (let i = 0; i < contactsCount; i++) {
    result.push({
      fullname: "Person name",
      avatar: `https://randomuser.me/api/portraits/${
        fastRandom(24) % 2 ? "women" : "men"
      }/${i}.jpg`,
      // phone: `+1${fastRandom(9).toString().repeat(9)}`,
      phone: "+1786-739-2067",
      online: fastRandom(15) % 2,
    });
  }

  return result;
})();

const crmCallCenterModule = {
  namespaced: true,
  state: (): any => {
    return {
      dialogmakeCall: false,
      //Call progress
      callinprogress: false,
      //
      contacts: contactsList,
      onlineWorkers: [],
      callList: [],
      missList: [],
      recordList: [],
      loading: false,
      loadingfilter: false,
      callsLead: [],
      totalcalls: 0,
      totalmiss: 0,
      totalrecord: 0,
      requestC: null,
      filter: false,
      view: "call",
    };
  },
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default crmCallCenterModule;
