import Company from "@/models/Company";

export function mutSetCompanies(state: any, companies: Company[]): void {
  state.companies = companies;
}

export function mutUpdateCompanies(state: any, companies: Company[]): void {
  state.companies = [...state.companies, ...companies];
}

export function mutLoading(state: any, loading: boolean): void {
  state.loading = loading;
}
