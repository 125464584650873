import i18n from "@/i18n";
import isNumber from "lodash/isNumber";

const rules = {
  required: (value: string): boolean | string => {
    if (value == null) return "";

    if (typeof value === "string") {
      return (
        value.trim() !== "" ||
        (i18n.locale == "en"
          ? "This field is required"
          : "Este campo es obligatorio")
      );
    }

    return !isNaN(value);
  },
  email: (value: string): boolean | string => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
      pattern.test(value) ||
      (i18n.locale == "en" ? "Invalid email" : "Dirección de correo inválida")
    );
  },
  emailnull: (value: string): boolean | string => {
    if (value == "" || value == null) {
      return true;
    }
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
      pattern.test(value) ||
      (i18n.locale == "en" ? "Invalid email" : "Dirección de correo inválida")
    );
  },
  noZero: (value: string): boolean | string => {
    return parseInt(value) > 0 || "Not can be zero";
  },
  isNumber: (value: string | number): boolean | string => {
    const message = "Is not numeric";

    if (typeof value == "number") {
      return isNumber(value) || message;
    }

    return /^[0-9][0-9]*\.[0-9]{2}$/.test(value as string) || message;
  },
  noEmpty: (value: string): boolean | string => {
    return value !== "" || "Can't be empty";
  },
  isVisaCard: (value: string): boolean | string => {
    return (
      /^4[0-9]{12}(?:[0-9]{3})?$/.test(value) || "Must be a Visa card number"
    );
  },
  isAmex: (value: string): boolean | string => {
    return (
      /^3[47]\d{1,2}(| |-)\d{6}\1\d{6}$/.test(value) ||
      "Must be a AMEX card number"
    );
  },
  isDiscover: (value: string): boolean | string => {
    return (
      /^6(?:011|5\d\d)(| |-)(?:\d{4}\1){2}\d{4}$/.test(value) ||
      "Must be a Discover card number"
    );
  },
  isMasterCard: (value: string): boolean | string => {
    return (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        value
      ) || "Must be a Mastercard card number"
    );
  },
  isCvv: (value: string): boolean | string => {
    return (
      /^[0-9]{3,4}$/.test(value) || "Invalid CVV, must be a 3–4 digit number"
    );
  },
  isZipCode(value: string): boolean | string {
    if (value == null || value == "") return true;
    return (
      /^[0-9]{5}$/.test(value) || "Invalid zip code, must be a 5 digit number"
    );
  },
  isPhone: (value: string): boolean | string => {
    if (value == null || value == "") return "";

    return (
      // /^\u002b\u0031[0-9]{10}$/.test(value) ||
      /^[1-9][0-9]{9}$/.test(value) ||
      "Invalid phone number, the right format is: ##########"
    );
  },
  isPhone1: (value: string): boolean | string => {
    if (value == null || value == "") return true;
    return (
      // /^\u002b\u0031[0-9]{10}$/.test(value) ||
      /^[1-9][0-9]{9}$/.test(value) ||
      "Invalid phone number, the right format is: ##########"
    );
  },
};

export default rules;
