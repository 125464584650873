import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifyInfo,
} from "@/components/Notification";
import Company from "@/models/Company";
import { AxiosError } from "axios";

const LIST = "companies/list";
const CREATE = "companies/create";
const DELETE = "companies/delete/";
const UPDATE = "companies/update/";

export async function actGetCompanies({ commit }: any): Promise<any> {
  try {
    commit("mutLoading", true);
    const responseData = (await getAPI(LIST)).data;

    commit("mutSetCompanies", responseData);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actCreateCompany(
  { commit, dispatch }: any,
  body: Company
): Promise<any> {
  try {
    commit("mutLoading", true);
    await getAPI.post(CREATE, body);
    await dispatch("actGetCompanies");
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

// Pendiente
export async function actRemoveCompany(
  { commit, dispatch }: any,
  uuid: string
): Promise<any> {
  try {
    commit("mutLoading", true);
    getAPI.delete(DELETE + uuid);
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);
    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}

export async function actUpdateCompany(
  { commit, dispatch }: any,
  { body, uuid }: { body: any; uuid: string }
): Promise<any> {
  try {
    commit("mutLoading", true);
    getAPI.post(UPDATE + uuid, body);
    await dispatch("actGetCompanies");

    notifyInfo("Company updated successfully");
    commit("mutLoading", false);
  } catch (error: any) {
    commit("mutLoading", false);

    let mess = error.response.data.message;

    if (mess.includes("[")) {
      mess = mess.replace("[", "");
      mess = mess.replace("]", "");
    }
    notifyError(error.response.data, `An error occurred: ${mess}`);
  }
}
