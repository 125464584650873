/**
 * Muestra/Oculta componentes según el rol del user logueado
 * @example
 * ```vue
 *    <allow-if worker>
 *      <your-component />
 *    </allow-if>
 * ```
 */
import { isDevelop } from "@/enviorment";
import Vue from "vue";
import { mapGetters } from "vuex";

const AllowIf = Vue.component(
  "allow-if",
  Vue.extend({
    props: {
      worker: Boolean,
      admin: Boolean,
      super: Boolean,
      doctor: Boolean,
      client: Boolean,
      agent: Boolean,
    },
    computed: {
      ...mapGetters([
        "isSuper",
        "isAdmin",
        "isWorker",
        "isAgent",
        "isDoctor",
        "isClient",
      ]),
    },
    render(h) {
      const child = (this.$slots as any).default[0];

      if (child == undefined || child == null) {
        //   isDevelop && console.error("You have to set a child component");
        return;
      }

      if (
        (this.worker && this.isWorker) ||
        (this.admin && this.isAdmin) ||
        (this.super && this.isSuper) ||
        (this.doctor && this.isDoctor) ||
        (this.agent && this.isAgent) ||
        (this.client && this.isClient)
      ) {
        return child;
      }

      return h();
    },
  })
);

export default AllowIf;
