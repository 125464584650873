
/**
 * To show the list of online workers
 */
import Vue from "vue";
import CallInProgress from "@/views/CallCenter/CallInProgress.vue";
import InputCompanies from "../InputCompanies.vue";
import AllowIf from "@/components/AllowIf";

import { rolColor } from "@/utils/rolesStatus";
import { mapActions, mapGetters, mapState } from "vuex";

export default Vue.extend({
  components: { CallInProgress, InputCompanies, AllowIf },
  name: "online-employees",
  props: {
    value: Boolean,
  },
  data() {
    return {
      showDrawer: false,
      mini: true,
      number: "",
      currentUi: 0,
      companyId: "",
      loading: false,
    };
  },
  computed: {
    ...mapState("crmCallCenterModule", ["onlineWorkers"]),
    ...mapGetters("crmCallCenterModule", ["contactsOnline"]),
  },
  methods: {
    ...mapActions("crmCallCenterModule", ["actOnlineWorkers"]),
    onInput(): void {
      this.$emit("input", this.value);
    },
    closeDrawer(): void {
      this.showDrawer = !this.showDrawer;
      this.mini = !this.mini;
      this.currentUi = 0;
      this.$emit("input", this.showDrawer);
    },
    makeCall(number: string): void {
      this.number = number;
      this.currentUi = 1;
    },
    rolColor,
  },
  watch: {
    value(val: boolean) {
      this.showDrawer = val;
    },
    companyId(uuid: string): void {
      this.loading = true;
      this.actOnlineWorkers(uuid).then(() => {
        this.loading = false;
      });
    },
  },
});
