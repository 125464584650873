
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";

export default Vue.extend({
  name: "btn-pause-call",
  props: {
    value: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      pausedCall: false
    };
  }
});
