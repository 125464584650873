import { AdminRoles } from "@/models/AdminRolesEnum";
import { Permission } from "./permision";

const rolesPermissionMap = new Map<AdminRoles, Permission[]>();
// SET ADMIN PERMISSIONS
rolesPermissionMap.set(AdminRoles.Admin, [
  Permission.EDIT_ADMIN,
  Permission.EDIT_WORKER,
  Permission.ADD_ADMIN,
  Permission.ADD_WORKER,
  Permission.LIST_ADMIN,
  Permission.LIST_WORKER,
  Permission.LIST_CLIENT,
  Permission.ADD_ORDER,
  Permission.LIST_MEMBERSHIPS,
  Permission.LIST_ORDERS,
  Permission.CONTACT_USER,
  Permission.LIST_CHECKER,
  Permission.ADD_CHECKER,
  Permission.EDIT_CHECKER,
  Permission.LIST_TICKET,
  Permission.EDIT_TICKET,
  Permission.LIST_TICKET,
  Permission.ADD_TICKET,
  Permission.LIST_EVENTS,
  Permission.ADD_EVENT,
  Permission.EDIT_EVENT,
  Permission.LIST_PRODUCT,
  Permission.ADD_PRODUCT,
  Permission.EDIT_PRODUCT,
  Permission.LIST_INVENTORY,
  Permission.ADD_INVENTORY,
  Permission.LIST_SALES
]);
// SET WORKER PERMISSIONS
rolesPermissionMap.set(AdminRoles.SuperAdmin, [
  Permission.LIST_SUPER_ADMIN,
  Permission.LIST_PRODUCT,
  Permission.ADD_PRODUCT,
  Permission.EDIT_PRODUCT,
  Permission.LIST_SALES
]);

export { rolesPermissionMap };
